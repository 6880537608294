import { createRoute } from 'utils/router.utils';

import { Routes } from 'constants/routes.constants';

import Collected from './Collected';

export default createRoute({
  element: <Collected />,
  path: Routes.Collected,
});
