import { createRoute } from 'utils/router.utils';

import { Routes } from 'constants/routes.constants';

import Permissions from './Permissions';

export default createRoute({
  element: <Permissions />,
  path: Routes.Permissions,
});
