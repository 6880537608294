import { Components, Theme } from '@mui/material';

export const MuiButton: Components<Theme>['MuiButton'] = {
  defaultProps: {
    disableElevation: true,
  },
  styleOverrides: {
    root: {
      textTransform: 'none',
    },
    contained: {
      borderRadius: 61,
      padding: '13px 0',
      fontSize: 24,
      fontWeight: 420,
      lineHeight: 'normal',
    },
    containedPrimary: ({ theme }) => ({
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
    }),
    containedSecondary: ({ theme }) => ({
      borderRadius: 61,
      padding: '13px 0',
      fontSize: 24,
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.background.default,
    }),
  },
};
