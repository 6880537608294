import { combineReducers } from 'redux';

import intlReducer from './intl/intl.slice';
import mapReducer from './map/map.slice';

const reducers = {
  intl: intlReducer,
  map: mapReducer,
};

const rootReducer = combineReducers(reducers);

export type RootReducer = typeof rootReducer;
export type Store = ReturnType<RootReducer>;

export default rootReducer;
