import { CssBaseline, ThemeProvider } from '@mui/material';
import { QueryClientProvider } from '@tanstack/react-query';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import IntlSelectorProvider from 'services/i18n';
import { queryClient } from 'services/react-query';
import storeConfig from 'store';
import theme from 'theme';

import Pages from 'pages/Pages';

import { RootBoundary } from 'components/@boundaries';
import { Layout } from 'components/@layout';
import { LoadingState } from 'components/@states';

const App = () => {
  window.udata = window.udata || [];
  return (
    <RootBoundary>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ReduxProvider store={storeConfig.store}>
            <PersistGate
              loading={<LoadingState />}
              persistor={storeConfig.persistor}
              onBeforeLift={storeConfig.initApp}
            >
              <IntlSelectorProvider>
                <HelmetProvider>
                  <Layout>
                    <Pages />
                  </Layout>
                </HelmetProvider>
              </IntlSelectorProvider>
            </PersistGate>
          </ReduxProvider>
        </ThemeProvider>
        {/* <ReactQueryDevtools initialIsOpen={false} position="bottom-right" /> */}
      </QueryClientProvider>
    </RootBoundary>
  );
};

export default App;
