import { Components, Theme } from '@mui/material';

import BrandonGrotesqueBlackWoff from 'assets/fonts/BrandonGrotesque-Black.woff';
import BrandonGrotesqueBlackWoff2 from 'assets/fonts/BrandonGrotesque-Black.woff2';
import BrandonGrotesqueBoldWoff from 'assets/fonts/BrandonGrotesque-Bold.woff';
import BrandonGrotesqueBoldWoff2 from 'assets/fonts/BrandonGrotesque-Bold.woff2';
import BrandonGrotesqueMediumWoff from 'assets/fonts/BrandonGrotesque-Medium.woff';
import BrandonGrotesqueMediumWoff2 from 'assets/fonts/BrandonGrotesque-Medium.woff2';
import BrandonGrotesqueRegularWoff from 'assets/fonts/BrandonGrotesque-Regular.woff';
import BrandonGrotesqueRegularWoff2 from 'assets/fonts/BrandonGrotesque-Regular.woff2';

export const MuiCssBaseline: Components<Theme>['MuiCssBaseline'] = {
  styleOverrides: `
    @font-face {
      font-family: 'Brandon Grotesque';
      font-style: normal;
      font-weight: 400;
      src: local('Brandon Grotesque'), url(${BrandonGrotesqueRegularWoff2}) format('woff2'),  url(${BrandonGrotesqueRegularWoff}) format('woff')
    }
    @font-face {
      font-family: 'Brandon Grotesque';
      font-style: normal;
      font-weight: 500;
      src: local('Brandon Grotesque'), url(${BrandonGrotesqueMediumWoff2}) format('woff2'),  url(${BrandonGrotesqueMediumWoff}) format('woff')
    }
    @font-face {
      font-family: 'Brandon Grotesque';
      font-style: normal;
      font-weight: 700;
      src: local('Brandon Grotesque'), url(${BrandonGrotesqueBoldWoff2}) format('woff2'),  url(${BrandonGrotesqueBoldWoff}) format('woff')
    }
    @font-face {
      font-family: 'Brandon Grotesque';
      font-style: normal;
      font-weight: 900;
      src: local('Brandon Grotesque'), url(${BrandonGrotesqueBlackWoff2}) format('woff2'),  url(${BrandonGrotesqueBlackWoff}) format('woff')
    }
  `,
};
