import { createRoute } from 'utils/router.utils';

import { Routes } from 'constants/routes.constants';

import Code from './Code';

export default createRoute({
  element: <Code />,
  path: Routes.Code,
});
