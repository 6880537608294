import { Box } from '@mui/material';
import { PropsWithChildren } from 'react';

import { Icon } from 'components/@common';

const Header = ({ children }: PropsWithChildren<{}>) => {
  return (
    <Box width="100%" bgcolor="divider" px={2.5} pt={2.5} pb={1.5}>
      <Icon name="IcoLogo" fontSize={16} />
      {children}
    </Box>
  );
};

export default Header;
