import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import { Routes } from 'constants/routes.constants';
import { Route as RouteProps } from 'types/routes.types';

interface Props extends RouteProps {
  children: ReactNode;
}

const RouteProtected = ({ children }: Props) => {
  const isAuthorized = true; // TODO: - use actual user data

  if (!isAuthorized) {
    return <Navigate to={Routes.RedirectNotAuthorized} replace />;
  }

  return <>{children}</>;
};

export default RouteProtected;
