import { createRoute } from 'utils/router.utils';

import { Routes } from 'constants/routes.constants';

import Info from './Info';

export default createRoute({
  element: <Info />,
  path: Routes.Root,
});
