import { configureStore } from '@reduxjs/toolkit';
import { Middleware, Reducer, Store } from 'redux';
import { createLogger } from 'redux-logger';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PersistConfig,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';

import { runInDev } from 'utils/env.utils';

const middlewares: Middleware[] = [];

// Only add the logger middleware in dev
runInDev(() =>
  middlewares.push(
    createLogger({
      collapsed: true,
      level: 'info',
    }),
  ),
);

interface Options {
  enableDevTools: boolean;
  persistConfig: PersistConfig<Store>;
  initApp(store: Store): Promise<void>;
}

const generateStore = <ReducerType extends Reducer>(
  combinedReducers: ReducerType,
  options: Options,
) => {
  const rootReducer = persistReducer(options.persistConfig, combinedReducers);
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: false,
        serializableCheck: {
          // redux persist does not pass the serializable check defined
          // Why should type be a string, or at least serializable:  https://redux.js.org/faq/actions/#why-should-type-be-a-string-or-at-least-serializable-why-should-my-action-types-be-constants
          // issue: https://github.com/rt2zz/redux-persist/issues/988
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat(middlewares),
    devTools: options.enableDevTools,
  });

  const persistor = persistStore(store);

  const initApp = async () => await options.initApp(store);

  return { store, persistor, initApp };
};

export default generateStore;
