import { Suspense } from 'react';
import { RouterProvider } from 'react-router-dom';

import { sentryCreateBrowserRouter } from 'services/sentry';

import { RouteBoundary } from 'components/@boundaries';

import { routes } from './routes';

const router = sentryCreateBrowserRouter(routes);

const Pages = () => (
  <Suspense>
    <RouteBoundary>
      <RouterProvider router={router} />
    </RouteBoundary>
  </Suspense>
);

export default Pages;
