import { createRoute } from 'utils/router.utils';

import { Routes } from 'constants/routes.constants';

import Conditions from './Conditions';

export default createRoute({
  element: <Conditions />,
  path: Routes.Conditions,
});
