import { createTheme } from '@mui/material/styles';

/**
 *   Setup base theme allows to be imported at other places (ex, palette, typography, spacing,...)
 */
export const baseTheme = createTheme({
  palette: {
    primary: {
      main: '#FDC400',
    },
    secondary: {
      main: '#000000',
    },
    background: {
      default: '#FBF6EE',
      paper: '#FFFFFF',
    },
    info: {
      main: '#FDE89D',
    },
    divider: '#3E4B49',
  },
  typography: {
    fontSize: 16,
    fontFamily: 'Brandon Grotesque, sans-serif',
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontSize: '2.5rem',
      lineHeight: 0.9,
    },
    h2: {
      fontSize: '2.25rem',
    },
    h3: {
      fontSize: '1.5rem',
    },
    body1: {
      fontSize: '1rem',
    },
    body2: {
      fontSize: '1.2rem',
    },
  },
});
