import { createRoute } from 'utils/router.utils';

import { Routes } from 'constants/routes.constants';

import Map from './Map';

export default createRoute({
  element: <Map />,
  path: Routes.Map,
});
