import { Box } from '@mui/material';
import { PropsWithChildren } from 'react';

const Card = ({ children, id }: PropsWithChildren<{ id: string }>) => {
  return (
    <Box
      id={id}
      bottom={16}
      right={0}
      left={0}
      width="75%"
      margin="0 auto"
      position="fixed"
      borderRadius="32px"
      bgcolor="background.default"
      px={3.75}
      py={4.75}
      textAlign="center"
      boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.05)"
    >
      {children}
    </Box>
  );
};

export default Card;
