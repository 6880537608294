export enum Routes {
  NotFound = '*',
  Root = '/',
  Map = '/map',
  Ar = '/ar',
  Collected = '/collected',
  Permissions = '/permissions',
  NoPermissions = '/no-permissions',
  Info = '/info',
  Code = '/code',
  Conditions = '/conditions',

  // generic routes
  RedirectNotAuthorized = '/login',
}
