import React from 'react';

export default {
  IcoCamera: React.lazy(() => import('./IcoCamera')),
  IcoLocation: React.lazy(() => import('./IcoLocation')),
  IcoLogo: React.lazy(() => import('./IcoLogo')),
  IcoMotion: React.lazy(() => import('./IcoMotion')),
  IcoError: React.lazy(() => import('./IcoError')),
  LogoPositive: React.lazy(() => import('./LogoPositive')),
};
