import './Map.css';

import mapboxClient from '@mapbox/mapbox-sdk/';
import mapboxDatasetClient from '@mapbox/mapbox-sdk/services/datasets';
import * as turf from '@turf/turf';
import Cookies from 'js-cookie';
import mapboxgl from 'mapbox-gl';
import { useCallback, useEffect, useState } from 'react';

import { CRUMBLE_RANGE, NECESSARY_CRUMBLES } from 'constants/map.constants';

import MapInfo from './MapInfo';
import MapPopup from './MapPopup';
import MemoMap from './MemoMap';

// TODO
// - cache the map in redux - memo not working?

const MAPBOX_ACCESS_TOKEN =
  'pk.eyJ1IjoicnViZW5uNSIsImEiOiJjbG11ZmNoc2wwZzgwMmxsZTVmMjNkNHl0In0.8nQgwHFoRMVnSnB6wla7lw';
const MAPBOX_DATASET_CRUMBLES_ID = 'clmyv4mao072n2kmugykwqx4o';
const MAPBOX_DATASET_POI_ID = 'cln1fp0ng098n2ipmhx4edi7q';
mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;

// Map icon pulse
const max_size = 0.35;
const min_size = 0.2;
let step_size = 0.005;

const Map = () => {
  const mpb = mapboxClient({ accessToken: MAPBOX_ACCESS_TOKEN });
  const datasetsClient = mapboxDatasetClient(mpb);

  const [map, setMap] = useState<mapboxgl.Map | null>(null);

  const [closestCrumbleId, setClosestCrumbleId] = useState('');
  const [user, setUser] = useState<mapboxgl.Marker | null>(null);
  const [crumbles, setCrumbles] = useState([]);
  const [crumbleMarkers, setCrumbleMarkers] = useState([]);
  const [poi, setPOI] = useState([]);
  const [enoughCrumbles, setEnoughCrumbles] = useState(false);
  const [outOfBounds, setOutOfBounds] = useState(false);
  const [mapCentered, setMapCentered] = useState(false);
  const [gatheredCrumbles, setGatheredCrumbles] = useState(
    Cookies.get('gatheredCrumbles')
      ? Cookies.get('gatheredCrumbles').split(',')
      : [],
  );

  const getMobileOperatingSystem = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone';
    }

    if (/android/i.test(userAgent)) {
      return 'Android';
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS';
    }

    return 'unknown';
  };

  const addCrumble = useCallback(
    (feature: { id: string; geometry: { coordinates: any } }) => {
      if (!gatheredCrumbles.includes(feature.id)) {
        const el = document.createElement('div');
        el.className = 'crumble';
        el.id = feature.id;

        const elIcon = document.createElement('div');
        elIcon.className = 'crumble_icon';
        const elIconVersion = Math.floor(Math.random() * (3 - 1 + 1) + 1);
        elIcon.style.backgroundImage =
          'url(../assets/crumbs/crumb_' + elIconVersion + '.png)';
        el.appendChild(elIcon);

        const coordinates = feature.geometry.coordinates;
        const newMarker = new mapboxgl.Marker(el)
          .setLngLat(coordinates)
          .addTo(map);
        setCrumbleMarkers([...crumbleMarkers, newMarker]);
      }
    },
    [gatheredCrumbles, map],
  );

  const addPOI = useCallback(
    (feature: {
      properties: { icon: string; title: string };
      id: string;
      geometry: { coordinates: any };
    }) => {
      map.loadImage(
        './assets/pins/' + feature.properties.icon,
        (error, image) => {
          if (error) console.log(error);

          // Add the image to the map style.
          map.addImage(feature.properties.title, image);

          // Add a data source containing one point feature.
          map.addSource(feature.id, {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: [
                {
                  type: 'Feature',
                  geometry: {
                    type: 'Point',
                    coordinates: feature.geometry.coordinates,
                  },
                },
              ],
            },
          });

          // Add a layer to use the image to represent the data.
          map.addLayer({
            id: 'poi-' + feature.id,
            type: 'symbol',
            source: feature.id, // reference the data source
            layout: {
              'icon-image': feature.properties.title, // reference the image
              'icon-size': 0.25,
              // 'text-field': feature.properties.title, // Display a description for each image
              // 'text-size': 15,
            },
          });
        },
      );
    },
    [map],
  );

  const isCloseToCrumble = useCallback(
    (user: mapboxgl.Marker) => {
      const options = {
        units: 'meters',
      };

      const user_co = user?.getLngLat();
      let closest_crumble: string | number | null = null;
      let closest_crumble_id: string | null = null;

      crumbles.forEach((feature) => {
        if (!gatheredCrumbles.includes(feature.id)) {
          const coordinates = feature.geometry.coordinates;
          const distance = turf.distance(
            [user_co.lng, user_co.lat],
            coordinates,
            options,
          );

          if (closest_crumble == null || distance < closest_crumble) {
            closest_crumble = distance;
            closest_crumble_id = feature.id;
          }
        }
      });

      if (closest_crumble !== null && closest_crumble < CRUMBLE_RANGE) {
        setClosestCrumbleId(closest_crumble_id);
        // if (navigator.vibrate) navigator.vibrate([100, 200]); // ios browsers does not support vibrate API --> NEEDS A USER GESTURE
      } else {
        setClosestCrumbleId(null);
      }
    },
    [crumbles, gatheredCrumbles],
  );

  const isOutOfBounds = useCallback(
    (user) => {
      if (map !== null) {
        if (!map.getMaxBounds().contains(user.getLngLat())) {
          setOutOfBounds(true);
        } else {
          setOutOfBounds(false);
        }
      }
    },
    [map],
  );

  useEffect(() => {
    if (gatheredCrumbles.length >= NECESSARY_CRUMBLES) {
      setEnoughCrumbles(true);
    }
  }, [gatheredCrumbles, map]);

  useEffect(() => {
    if (map !== null && enoughCrumbles) {
      // remove all crumbles
      crumbleMarkers.forEach((marker: mapboxgl.Marker) => {
        marker.remove();
      });

      map.on('render', () => {
        // Pulsing LPQ logo
        const lpq_feature = poi.filter(
          (feature) => feature.properties.type === 'lpq',
        )[0];
        if (lpq_feature) {
          const layer = map.getLayer('poi-' + lpq_feature.id);

          if (layer) {
            const current_size = map.getLayoutProperty(
              'poi-' + lpq_feature.id,
              'icon-size',
            );
            if (current_size < max_size && current_size > min_size) {
              map.setLayoutProperty(
                'poi-' + lpq_feature.id,
                'icon-size',
                current_size + step_size,
              );
            } else {
              step_size = -step_size;
              map.setLayoutProperty(
                'poi-' + lpq_feature.id,
                'icon-size',
                current_size + step_size,
              );
            }
          }
        }
      });
    }
  }, [crumbleMarkers, enoughCrumbles, map, poi, user]);

  useEffect(() => {
    if (map !== null && enoughCrumbles !== true) {
      crumbles.forEach((feature) => {
        addCrumble(feature);
      });
    }
  }, [crumbles, map, addCrumble, enoughCrumbles]);

  useEffect(() => {
    if (map !== null) {
      poi.forEach((feature) => {
        addPOI(feature);
      });
    }
  }, [poi, map, addPOI]);

  useEffect(() => {
    // Get user's compass heading
    window.addEventListener('deviceorientation', (event) => {
      if (event.alpha !== null) {
        const adjustedHeading = event.webkitCompassHeading || event.alpha;
        user?.setRotation(adjustedHeading);
      }
    });

    // Get user's geolocation
    const watchId = navigator.geolocation.watchPosition(
      (position) => {
        const { latitude, longitude, heading } = position.coords;
        if (map) {
          user?.setLngLat([longitude, latitude]);
          // user?.setRotation(heading); // Comes from deviceorientation
          user?.addTo(map); // Will not double add --> TODO does it trigger map refresh?

          if (!mapCentered & !enoughCrumbles && !outOfBounds) {
            map.flyTo({
              center: [longitude, latitude],
              zoom: 16,
            });
            setMapCentered(true);
          }

          isOutOfBounds(user);
          isCloseToCrumble(user);
        }
      },
      (error) => {
        console.error('Error getting user location:', error);
      },
      { enableHighAccuracy: true }, // maximumAge ?
    );

    // Cleanup function to remove the map when the component unmounts
    return () => {
      navigator.geolocation.clearWatch(watchId); // Stop tracking when component unmounts
    };
  }, [user, map, isOutOfBounds, isCloseToCrumble, enoughCrumbles, outOfBounds]);

  useEffect(() => {
    if (map !== null) {
      map.on('load', () => {
        // Add crumbs
        datasetsClient
          .listFeatures({
            datasetId: MAPBOX_DATASET_CRUMBLES_ID,
          })
          .send()
          .then((response) => {
            setCrumbles(response.body.features);
          });

        // Add POI
        datasetsClient
          .listFeatures({
            datasetId: MAPBOX_DATASET_POI_ID,
          })
          .send()
          .then((response) => {
            setPOI(response.body.features);
          });
      });
    }
  }, [map]);

  useEffect(() => {
    const el = document.createElement('div');
    el.id = 'user';
    el.style.backgroundImage =
      getMobileOperatingSystem() == 'iOS'
        ? 'url(../assets/arrow.png)'
        : 'url(../assets/arrow-android.png)';
    const new_user = new mapboxgl.Marker(el);
    setUser(new_user);
  }, []); // The empty dependency array ensures this effect runs only once

  const handleSetMap = useCallback((map: mapboxgl.Map) => {
    setMap(map);
  }, []);

  return (
    <div>
      <MapInfo gatheredCrumbles={gatheredCrumbles} />
      <MemoMap setMap={handleSetMap} />
      <MapPopup
        closestCrumbleId={closestCrumbleId}
        enoughCrumbles={enoughCrumbles}
        outOfBounds={outOfBounds}
      />
    </div>
  );
};

export default Map;
