import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface MapState {
  mapContainer: mapboxgl.Map | null;
}

const initialState: MapState = {
  mapContainer: null,
};

const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    SET_MAP: (state, { payload }: PayloadAction<MapState['mapContainer']>) => {
      state.mapContainer = payload;
    },
  },
});

export const { actions: mapActions } = mapSlice;

export default mapSlice.reducer;
