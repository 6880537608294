import { Box, Button, Link as MuiLink, Typography } from '@mui/material';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { COUPON_CODES } from 'constants/code.constants';
import { NECESSARY_CRUMBLES } from 'constants/map.constants';
import { Routes } from 'constants/routes.constants';

import { Header } from 'components/@header';

const Code = () => {
  const navigate = useNavigate();
  const [couponCode, setCouponCode] = useState(
    Cookies.get('couponCode') ||
      COUPON_CODES[Math.floor(Math.random() * COUPON_CODES.length)],
  );

  useEffect(() => {
    if (
      Cookies.get('gatheredCrumbles') &&
      Cookies.get('gatheredCrumbles').split(',').length >= NECESSARY_CRUMBLES
    ) {
      Cookies.set('couponCode', couponCode, { expires: 1 });
    } else {
      // User should not arrive here yet
      navigate(Routes.Map);
    }
  }, [couponCode]);

  const playAgain = () => {
    // Clear cookies
    Cookies.remove('gatheredCrumbles');
    Cookies.remove('couponCode');
    // Navigate to map
    navigate(Routes.Map);
  };

  return (
    <Box pb={1}>
      <Header />
      <Box textAlign="center" px={3.75} pt={5} pb={4}>
        <Box>
          <img src="assets/cookie.png" width="180rem" />
        </Box>
        <Typography variant="h1" fontWeight={700} py={2} lineHeight="50px">
          Proficiat, je wint een cookie !
        </Typography>
        <Typography variant="body2" py={2} fontWeight={420}>
          Je hebt {NECESSARY_CRUMBLES} kruimels verzameld. Gebruik deze code om
          je cookie te ontvangen.
        </Typography>
        <Typography variant="h2" fontWeight={700} my={3}>
          {couponCode}
        </Typography>
        <Typography py={3} fontWeight={420} variant="body2">
          Ga naar Le Pain Quotidien De Wael, toon bovenstaande code en ontvang
          je geschenk.
        </Typography>
        <Typography fontWeight={700} variant="body2">
          Hoe geraak je hier?
        </Typography>
        <Typography variant="body2">Leopold de Waelplaats 8</Typography>
        <Typography variant="body2">2000 Antwerpen</Typography>
        <MuiLink
          component={Link}
          color="primary.main"
          variant="body2"
          to={Routes.Map}
        >
          Open kaart
        </MuiLink>
      </Box>
      <Box textAlign="center" px={3.75} py={4} bgcolor="primary.main">
        <Box>
          <Typography fontWeight={700} variant="body2">
            Nog eens spelen?
          </Typography>
          <Typography variant="body2">Wil je de zoektocht herhalen,</Typography>
          <Typography variant="body2">speel ze dan opnieuw!</Typography>
          <MuiLink
            component={Link}
            color="background.paper"
            variant="body2"
            onClick={playAgain}
            id="game_restarted"
          >
            Speel opnieuw
          </MuiLink>
        </Box>
      </Box>
      <Box
        color="background.paper"
        mx={3.75}
        mt={8}
        mb={3}
        bgcolor="primary.main"
      >
        <img src="assets/tartine_club.png" width="100%" />
        <Box px={2} pt={1} py={2}>
          <Typography variant="h3" fontWeight={700}>
            Word lid van onze Tartine Club
          </Typography>
          <Typography pb={2}>
            Wil je nog kans maken op leuke acties, promoties en meer? Word dan
            lid van onze Tartine Club.
          </Typography>
          <Button
            fullWidth
            variant="containedSecondary"
            component={Link}
            my={1}
            target="_blank"
            to={
              'https://lpqloyaltystag.b2clogin.com/lpqloyaltystag.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_lpq_userflow_prod&client_id=55b77229-e691-4750-8b66-35d176e52c17&nonce=defaultNonce&redirect_uri=https://tartineclub.lepainquotidien.com/&scope=openid%20https://lpqloyaltystag.onmicrosoft.com/653ca9af-60da-4739-8d7f-43b27ca42a09/Api.Mngmt&response_type=id_token%20token&prompt=login'
            }
            id="opened_tartine_club"
          >
            Ontdek Tartine Club
          </Button>
        </Box>
      </Box>
      <Box textAlign="center" mb={2}>
        <MuiLink
          component={Link}
          color="secondary.main"
          variant="body2"
          to={Routes.Conditions}
        >
          Onze actievoorwaarden
        </MuiLink>
      </Box>
    </Box>
  );
};

export default Code;
