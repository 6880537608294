import { Box, Button, Typography } from '@mui/material';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { NECESSARY_CRUMBLES } from 'constants/map.constants';
import { Routes } from 'constants/routes.constants';

import { Header } from 'components/@header';

const Collected = () => {
  const location = useLocation();
  const { crumbleId } = location.state;
  const [enoughCrumbles, setEnoughCrumbles] = useState(false);
  const [newlyGathered, setNewlyCrumbles] = useState([]);

  useEffect(() => {
    const gatheredCrumbles = Cookies.get('gatheredCrumbles')
      ? Cookies.get('gatheredCrumbles').split(',')
      : [];
    if (!gatheredCrumbles.includes(crumbleId)) {
      setNewlyCrumbles([...gatheredCrumbles, crumbleId]);
    } else {
      setNewlyCrumbles(gatheredCrumbles);
    }
  }, [crumbleId]);

  useEffect(() => {
    if (newlyGathered.length > 0) {
      Cookies.set('gatheredCrumbles', newlyGathered, { expires: 1 });
      if (newlyGathered.length >= NECESSARY_CRUMBLES) {
        setEnoughCrumbles(true);
        window.udata?.push({
          event_name: 'page navigation',
          env_name: 'dewael-crumble',
          page_language: 'nl',
          page_name: 'gift received',
          page_template: 'other',
          page_category: ['game', 'crumble'],
        });
      }
    }
  }, [newlyGathered]);

  return (
    <>
      <Header />
      <Box textAlign="center" px={3.75} py={5}>
        <Typography variant="h1" fontWeight={700} py={2}>
          {enoughCrumbles ? 'Proficiat !' : 'Goed gedaan !'}
        </Typography>
        <Typography variant="h3" pt={2}>
          {enoughCrumbles
            ? 'Je hebt genoeg kruimels verzameld om je geschenk te ontvangen.'
            : 'Je hebt een kruimel verzameld.'}
        </Typography>
        {enoughCrumbles ? (
          <Box>
            <img src="assets/crumbs/group_crumb.svg" height={180} />
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            py={6}
          >
            <Typography variant="h1" fontWeight={700} display="flex" px={3}>
              +1
            </Typography>
            <img src="assets/crumbs/crumb_2.svg" height={180} display="flex" />
          </Box>
        )}
        <Typography mt={1} mb={5}>
          {enoughCrumbles
            ? 'Je hebt genoeg kruimels verzameld om je geschenk te ontvangen.'
            : 'Je hebt ' +
              newlyGathered.length +
              '/' +
              NECESSARY_CRUMBLES +
              ' kruimels verzameld. Zoek er nog ' +
              (NECESSARY_CRUMBLES - newlyGathered.length) +
              ' meer om een prijs te winnen.'}
        </Typography>
        <Button
          fullWidth
          variant="contained"
          component={Link}
          to={enoughCrumbles ? Routes.Code : Routes.Map}
          data-page="collected"
          id={
            enoughCrumbles
              ? 'receive_gift'
              : 'catched_crumb_' + newlyGathered.length
          }
        >
          {enoughCrumbles ? 'Ontvang je geschenk' : 'Zoek verder'}
        </Button>
      </Box>
    </>
  );
};

export default Collected;
