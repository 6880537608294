import { Box, Typography } from '@mui/material';

interface Props {
  gatheredCrumbles: string[];
}

const MapInfo = ({ gatheredCrumbles }: Props) => {
  return (
    <>
      <Box
        id="map-info"
        top={16}
        right={0}
        left={0}
        width="60%"
        margin="0 auto"
        position="fixed"
        borderRadius="24px"
        bgcolor="rgba(253, 232, 157, 0.70)"
        px={2}
        py={2}
        zIndex={10}
        boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.05)"
      >
        <Typography fontWeight={450} style={{ float: 'left', paddingTop: 4 }}>
          Verzameld
        </Typography>
        <Box style={{ float: 'right' }}>
          <Box
            className={
              'info_spot ' +
              (gatheredCrumbles.length > 0 ? 'info_crumble' : 'info_circle')
            }
          ></Box>
          <Box
            className={
              'info_spot ' +
              (gatheredCrumbles.length > 1 ? 'info_crumble' : 'info_circle')
            }
          ></Box>
          <Box
            className={
              'info_spot ' +
              (gatheredCrumbles.length > 2 ? 'info_crumble' : 'info_circle')
            }
          ></Box>
        </Box>
      </Box>
    </>
  );
};

export default MapInfo;
