/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box, Typography } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// import * as THREE from 'three';
import { Routes } from 'constants/routes.constants';

import { Card } from 'components/@card';

const spawnMarginStep = 0.00001;
const spawnMarginBounds = [-0.0003, 0.0003];
const captureAnimationMS = 500;
const crumbleScale = 1;

const Ar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const crumbleRef = useRef<Element>(null);
  const compoundRef = useRef<Element>(null);
  const [entityLatitude, setEntityLatitude] = useState<string>();
  const [entityLongitude, setEntityLongitude] = useState<string>();
  const crumbleId = location.state ? location.state.crumbleId : null;

  const getRandomCoordinate = (coordinate: number) => {
    const range =
      (spawnMarginBounds[1] - spawnMarginBounds[0]) / spawnMarginStep;
    let randomIndex = Math.floor(Math.random() * (range + 1)) - range / 2;
    // range = [-30, +30] --> [-5, +5] too close to camera
    if (randomIndex >= -5 && randomIndex <= 5) {
      if (Math.sign(randomIndex) == 1) {
        randomIndex += 5;
      } else {
        randomIndex -= 5;
      }
    }
    const randomCoordinate = coordinate + randomIndex * spawnMarginStep;

    return randomCoordinate;
  };

  const handleCompoundClick = useCallback(
    (ev: { detail: { intersectedEl: unknown } }) => {
      const intersectedElement = ev && ev.detail && ev.detail.intersectedEl;
      if (
        intersectedElement &&
        (intersectedElement.id == 'crumble-entity' ||
          intersectedElement.id == 'bread-entity')
      ) {
        window.udata?.push({
          event_name: 'interaction',
          interaction_name: 'impression: crumb catched',
        });

        if (navigator.vibrate) navigator.vibrate(200);
        // @ts-ignore
        crumbleRef.current?.setAttribute('animation__2', {
          property: 'scale',
          to: { x: crumbleScale * 3, y: crumbleScale * 3, z: crumbleScale * 3 },
          dur: captureAnimationMS, // Duration of the animation in milliseconds
          loop: false,
          easing: 'linear', // Easing function
        });
        setTimeout(() => {
          const html = document.querySelector('html');
          html?.classList.remove('a-fullscreen');

          const video = document.querySelector('video');
          video?.remove();
          navigate(Routes.Collected, {
            state: { crumbleId: crumbleId },
          });
        }, captureAnimationMS);
      }
    },
    [],
  );

  const handleEntityVisible = (e) => {
    console.log('Entity is now visible to the camera');
  };

  useEffect(() => {
    if (entityLatitude && entityLongitude) {
      crumbleRef.current = document.querySelector('#crumble-entity');

      compoundRef.current = document.querySelector('#bread-entity');
      compoundRef.current?.addEventListener('click', handleCompoundClick);
      compoundRef.current?.addEventListener(
        'raycaster-intersected',
        handleEntityVisible,
      );

      // Make 2d entity position itself towards camera
      const camera = document.querySelector('#camera');
      const position = camera.object3D.position;
      compoundRef.current?.object3D.lookAt(
        new THREE.Vector3(position.x, position.y, position.z),
      );
    }

    return () => {
      compoundRef.current?.removeEventListener('click', handleCompoundClick);
      compoundRef.current?.removeEventListener(
        'raycaster-intersected',
        handleEntityVisible,
      );
    };
  }, [entityLatitude, entityLongitude, handleCompoundClick]);

  useEffect(() => {
    if (!crumbleId) {
      navigate(Routes.NotFound);
    }

    const html = document.querySelector('html');
    html?.classList.add('a-fullscreen');

    const camera = document.querySelector('#camera');

    const handleCameraUpdate = async (e) => {
      if (crumbleRef.current === null) {
        // Setting entity coordinates
        const lat = getRandomCoordinate(e.detail.position.latitude);
        const long = getRandomCoordinate(e.detail.position.longitude);

        setEntityLatitude(lat);
        setEntityLongitude(long);
      }

      // Make 2d entity position itself towards camera
      if (camera && compoundRef.current) {
        const position = camera.object3D.position;
        compoundRef.current?.object3D.lookAt(
          new THREE.Vector3(position.x, position.y, position.z),
        );
      }
    };

    camera.addEventListener('gps-camera-update-position', handleCameraUpdate);

    return () => {
      // Cleanup: Remove event listener when the component unmounts
      camera.removeEventListener(
        'gps-camera-update-position',
        handleCameraUpdate,
      );
    };
  }, [crumbleId, navigate]);

  return (
    <Box id="arBox">
      <a-scene
        // embedded
        style={{ height: '100vh' }} // width: '100vw',
        vr-mode-ui="enabled: false"
        arjs="trackingMethod: best; sourceType: webcam; videoTexture: true; debugUIEnabled: false;" // sourceWidth:1280; sourceHeight:960; displayWidth: 1280; displayHeight: 960;
        renderer="antialias: true; alpha: true"
        device-orientation-permission-ui="enabled: false"
      >
        <a-entity light="type: ambient; color: #ffff00; intensity: 1"></a-entity>
        <a-entity raycaster="objects: .clickable;"></a-entity>
        <a-camera
          id="camera"
          gps-new-camera="gpsMinDistance: 1"
          position="0 1.6 0"
        ></a-camera>
        {entityLatitude && entityLongitude && (
          <a-entity
            id="bread-entity"
            class="clickable"
            look-at="#camera"
            emitevents="true"
            cursor="rayOrigin: mouse"
            data-lat={entityLatitude}
            data-long={entityLongitude}
            gps-new-entity-place={
              'latitude: ' + entityLatitude + '; longitude: ' + entityLongitude
            }
          >
            <a-image
              id="crumble-entity"
              class="clickable"
              look-at="#camera"
              src="./assets/crumbs/crumb_2.svg"
              width="5"
              height="6"
              scale={crumbleScale + ' ' + crumbleScale + ' ' + crumbleScale}
              position="0 -10 0"
              rotation="0 0 -20"
              animation__bounce="property: position; to: 0 -9 0; dur: 500; easing: linear; dir: alternate; loop: true"
              animation__wiggle="property: rotation; to: 0 0 20; dur: 800; easing: linear; dir: alternate; loop: true"
            />
            <a-image
              id="arrow-entity"
              class="clickable"
              look-at="#camera"
              src="./assets/arrow_yellow.svg"
              width="3"
              height="3"
              scale="1 1 1"
              position="0 0 0"
              animation__bounce="property: position; to: 0 -2 0; dur: 1000; easing: linear; dir: alternate; loop: true"
            />
          </a-entity>
        )}
      </a-scene>
      <Card id="ar-popup">
        <Typography>
          Kijk om je heen en ga op zoek naar de kruimel. Tap op de kruimel om
          hem te verzamelen
        </Typography>
      </Card>
    </Box>
  );
};

export default Ar;
