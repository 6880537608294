/**
 * import routes as following: "import [name] from './[name]'"
 * & add it to the array of routes
 */
import { RouteObject } from 'react-router-dom';

import ar from './ar';
import code from './code';
import collected from './collected';
import conditions from './conditions';
import info from './info';
import map from './map';
import noPermissions from './no-permissions';
import notFound from './not-found';
import permissions from './permissions';

export const routes: RouteObject[] = [
  map,
  ar,
  collected,
  permissions,
  noPermissions,
  info,
  code,
  conditions,
  notFound,
];
