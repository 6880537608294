import { createRoute } from 'utils/router.utils';

import { Routes } from 'constants/routes.constants';

import Ar from './Ar';

export default createRoute({
  element: <Ar />,
  path: Routes.Ar,
});
