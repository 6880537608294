import mapboxgl from 'mapbox-gl';
import { memo, useEffect } from 'react';

const MAP_CENTER = [4.393, 51.21];
// Set map bounds
const BOUNDS = [
  [4.386, 51.205], // Southwest coordinates
  [4.4, 51.214], // Northeast coordinates
];

interface Props {
  setMap: (map: mapboxgl.Map) => void;
}

// TODO map to provider?
const MemoMap = ({ setMap }: Props) => {
  const createMap = () => {
    return new mapboxgl.Map({
      container: 'map-container', // Specify the ID of the container element
      style: 'mapbox://styles/rubenn5/clmuffds002k401qxf7b25ds8', // Replace with your desired map style
      center: MAP_CENTER, // Replace with your desired initial center coordinates
      zoom: 6, // Replace with your desired initial zoom level
      maxZoom: 17,
      minZoom: 6,
      maxBounds: BOUNDS, // Set the map's geographical boundaries.
      bearing: 0, // Initialize the map with a default bearing of 0 degrees // VERSION 1 - no bearing view
      dragRotate: false, // Disable dragging to rotate
    });
  };

  useEffect(() => {
    // Create a new Mapbox GL map instance
    let new_map: mapboxgl.Map = createMap();
    let timer: string | number | NodeJS.Timeout | null | undefined = null;
    const canvas = new_map.getCanvas();

    const createNewMap = () => {
      // Mapbox-gl WebGL: context lost.
      console.log('WebGL context lost, creating new context');
      timer = setTimeout((e) => {
        // canvas.getContext('webgl')?.restore();
        setMap(null);
        new_map.remove();
        new_map = createMap();
        setMap(new_map);
      }, 100);
    };

    canvas.addEventListener('webglcontextlost', createNewMap);

    setMap(new_map);

    // Cleanup function to remove the map when the component unmounts
    return () => {
      canvas.removeEventListener('webglcontextlost', createNewMap);
      if (new_map.loaded()) {
        new_map.remove();
      }
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [setMap]);

  return <div id="map-container" style={{ height: '100vh' }}></div>;
};

export default memo(MemoMap);
