// comment out locales that should not be supported
import '@formatjs/intl-relativetimeformat/locale-data/en';
import '@formatjs/intl-relativetimeformat/locale-data/nl';

import { createIntl, createIntlCache, IntlShape } from 'react-intl';

import config from 'config';

import MESSAGES_EN from './translations/en.json';
import MESSAGES_NL from './translations/nl.json';

const { DEFAULT_LOCALE, DEFAULT_LOCALE_DATE } = config;

const locales = ['en-GB', 'nl-BE'];

const intlCache = createIntlCache();

const intl: { [key: string]: IntlShape } = {
  'en-GB': createIntl({ locale: 'en-GB', messages: MESSAGES_EN }, intlCache),
  'nl-BE': createIntl({ locale: 'nl-BE', messages: MESSAGES_NL }, intlCache),
};

export { DEFAULT_LOCALE, DEFAULT_LOCALE_DATE, intl, locales };
