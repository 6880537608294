import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Link as MuiLink,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Routes } from 'constants/routes.constants';

import { Icon } from 'components/@common';
import { Header } from 'components/@header';

interface DeviceEventIOS extends DeviceOrientationEvent {
  requestPermission: () => Promise<'granted' | 'denied'>;
}

const requestDeviceOrientationPermission = (
  DeviceOrientationEvent as unknown as DeviceEventIOS
).requestPermission;

const requestDeviceMotionPermission = (
  DeviceMotionEvent as unknown as DeviceEventIOS
).requestPermission;

// Needed: camera, location, motion and orientation
const Permissions = () => {
  const navigate = useNavigate();
  const [cameraPermission, setCameraPermission] = useState(null);
  const [motionPermission, setMotionPermission] = useState(null);
  const [orientationPermission, setOrientationPermission] = useState(null);
  const [termsChecked, setTermsChecked] = useState(false);

  useEffect(() => {
    if (
      motionPermission === true &&
      cameraPermission === true &&
      orientationPermission === true &&
      termsChecked === true
    ) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            // Navigate to map
            window.udata?.push({
              event_name: 'page navigation',
              env_name: 'dewael-crumble',
              page_language: 'nl',
              page_name: 'game started',
              page_template: 'other',
              page_category: ['game', 'crumble'],
            });
            navigate(Routes.Map);
          },
          (error) => {
            console.error(error);
            navigate(Routes.NoPermissions);
          },
          { enableHighAccuracy: true, timeout: 5000 },
        );
      } else {
        // I believe it may also mean geolocation isn't supported
        console.error('Geolocation denied');
        navigate(Routes.NoPermissions);
      }
    } else if (
      motionPermission === false ||
      cameraPermission === false ||
      orientationPermission === false
    ) {
      console.log('Not full permissions');
      navigate(Routes.NoPermissions);
    }
  }, [
    cameraPermission,
    motionPermission,
    navigate,
    orientationPermission,
    termsChecked,
  ]);

  // Has to be triggered on user action !!!!
  const givePermission = async () => {
    try {
      // Check camera permission status
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then(() => setCameraPermission(true))
        .catch((e) => {
          console.log('CameraPermission ƒalse');
          setCameraPermission(false);
        });

      // Check DeviceMotionEvent permission status
      if (
        typeof DeviceMotionEvent !== 'undefined' &&
        typeof requestDeviceMotionPermission === 'function'
      ) {
        requestDeviceMotionPermission().then((response: string) => {
          // (optional) Do something after API prompt dismissed.
          if (response == 'granted') {
            setMotionPermission(true);
          } else {
            console.log('DeviceMotionEvent ƒalse');
            setMotionPermission(false);
          }
        });
      } else {
        // Part of geolocation in Android
        console.log('DeviceMotionEvent Android');
        setMotionPermission(true);
      }

      // Check DeviceOrientationEvent permission status
      if (
        typeof DeviceOrientationEvent !== 'undefined' &&
        typeof requestDeviceOrientationPermission === 'function'
      ) {
        requestDeviceOrientationPermission().then((response: string) => {
          if (response == 'granted') {
            setOrientationPermission(true);
          } else {
            console.log('DeviceOrientationEvent ƒalse');
            setOrientationPermission(false);
          }
        });
      } else {
        // Part of geolocation in Android
        console.log('DeviceOrientationEvent Android');
        setOrientationPermission(true);
      }
    } catch (e) {
      console.error(e);
      navigate(Routes.NoPermissions);
    }
  };

  return (
    <>
      <Header />
      <Stack
        justifyContent="space-between"
        alignItems="center"
        bgcolor="background.default"
        px={4.75}
        height="100%"
        pb={6}
        pt={0}
      >
        <Box>
          <img
            src="assets/permission_map.png"
            width="80%"
            style={{ marginLeft: '10%', paddingTop: '8px' }}
          />
        </Box>
        <Typography textAlign="center" fontWeight={500} pt={0} pb={4}>
          Om dit spel te spelen, hebben we nog even jouw toestemming nodig voor
          volgende:
        </Typography>
        <Stack gap="32px" pb={4}>
          <Box display="flex" alignItems="center" gap={2}>
            <Icon name="IcoCamera" fontSize={34} />
            <Typography fontWeight={500}>
              Je camera, om de kruimels te zoeken.
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" gap={2}>
            <Icon name="IcoMotion" fontSize={56} />
            <Typography fontWeight={500}>
              Bewegen van je toestel, zodat je de kruimel kan zoeken en
              verzamelen.
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" gap={2}>
            <Icon name="IcoLocation" fontSize={56} />
            <Typography fontWeight={500}>
              Je locatie, zo kan je jezelf zien op de kaart en naar de kruimels
              wandelen.
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" gap={2}>
            <FormControlLabel
              control={<Checkbox />}
              style={{ margin: 'auto' }}
              checked={termsChecked}
              onChange={() => setTermsChecked(!termsChecked)}
              label={
                <Typography fontWeight={500}>
                  Aanvaard{' '}
                  <MuiLink
                    component={Link}
                    color="secondary.main"
                    to={Routes.Conditions}
                  >
                    onze actievoorwaarden
                  </MuiLink>
                </Typography>
              }
            />
          </Box>
          <Box display="flex" alignItems="center" gap={2}>
            <Button
              fullWidth
              variant="contained"
              onClick={givePermission}
              mt={2}
              id="permissions_requested"
              disabled={!termsChecked}
            >
              Sta toe
            </Button>
          </Box>
        </Stack>
      </Stack>
    </>
  );
};

export default Permissions;
