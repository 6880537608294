import { createRoute } from 'utils/router.utils';

import { Routes } from 'constants/routes.constants';

import NoPermissions from './NoPermissions';

export default createRoute({
  element: <NoPermissions />,
  path: Routes.NoPermissions,
});
