import { Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import { NECESSARY_CRUMBLES } from 'constants/map.constants';
import { Routes } from 'constants/routes.constants';

import { Card } from 'components/@card';

interface Props {
  closestCrumbleId: string;
  enoughCrumbles: boolean;
  outOfBounds: boolean;
}

const MapPopup = ({ closestCrumbleId, enoughCrumbles, outOfBounds }: Props) => {
  return (
    <>
      <Card id="map-popup">
        {closestCrumbleId && !enoughCrumbles && !outOfBounds && (
          <>
            <img
              src="assets/crumbs/crumb_2.svg"
              style={{
                width: '5rem',
                top: '-65px',
                left: '0',
                right: '0',
                margin: 'auto',
                position: 'absolute',
                // boxShadow: '0px 0px 52px rgba(253, 196, 0, 0.50)',
              }}
            />
            <Typography fontWeight={700}>
              Je hebt een kruimel ontdekt !
            </Typography>
            <Typography mb={1}>
              Je hebt een kruimel gevonden. Verzamel de kruimel door gebruik te
              maken van je camera.
            </Typography>
            <Button
              fullWidth
              variant="contained"
              component={Link}
              to={Routes.Ar}
              state={{ crumbleId: closestCrumbleId }}
              id="discovered_crumb"
              onClick={() => {
                window.udata?.push({
                  event_name: 'interaction',
                  interaction_name: 'impression: crumb discovered',
                });
              }}
            >
              Vang kruimel
            </Button>
          </>
        )}
        {enoughCrumbles && !outOfBounds && (
          <>
            <img
              src="assets/crumbs/group_crumb.svg"
              style={{
                width: '10rem',
                top: '-75px',
                left: '0',
                right: '0',
                margin: 'auto',
                position: 'absolute',
              }}
            />
            <Typography fontWeight={700}>
              Je hebt genoeg kruimels verzameld
            </Typography>
            <Typography mb={1}>
              Proficiat! Je hebt {NECESSARY_CRUMBLES} kruimels verzameld.
            </Typography>
            <Button
              fullWidth
              variant="contained"
              component={Link}
              to={Routes.Code}
              data-page="map"
              id="receive_gift"
            >
              Ontvang hier je geschenk
            </Button>
          </>
        )}
        {!closestCrumbleId && !enoughCrumbles && !outOfBounds && (
          <>
            <Typography fontWeight={700}>Ga op zoek naar kruimels</Typography>
            <Typography mb={1}>
              Verzamel {NECESSARY_CRUMBLES} kruimels om een geschenk te winnen.
              Stap naar een kruimel en kijk om je heen.
            </Typography>
          </>
        )}
        {outOfBounds && (
          <>
            <Typography fontWeight={700}>Je bent te ver</Typography>
            <Typography mb={1}>
              Dit spel kan alleen gespeeld worden in de buurt van Le Pain
              Quotidien De Wael.
            </Typography>
          </>
        )}
      </Card>
    </>
  );
};

export default MapPopup;
