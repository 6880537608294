import { Box, Button, Typography } from '@mui/material';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { NECESSARY_CRUMBLES } from 'constants/map.constants';
import { Routes } from 'constants/routes.constants';

import { Header } from 'components/@header';

const Info = () => {
  useEffect(() => {
    window.udata?.push({
      event_name: 'page navigation',
      env_name: 'dewael-crumble',
      page_language: 'nl',
      page_name: 'game intro',
      page_template: 'other',
      page_category: ['game', 'crumble'],
    });
  }, []);
  return (
    <>
      <Header />
      <Box position="relative" height="100svh" overflow="hidden">
        <Box position="absolute" top={0} right={0} bottom={0} left={0}>
          <img
            src="assets/poster.png"
            width="100%"
            height="auto"
            style={{ objectFit: 'cover' }}
          />
        </Box>
      </Box>
      <Box
        bottom={0}
        right={0}
        left={0}
        position="fixed"
        bgcolor="background.default"
        px={3.75}
        py={4.75}
        textAlign="center"
      >
        <Typography variant="h2" fontWeight={700} mb={1}>
          Start de zoektocht
        </Typography>
        <Typography mb={5.75}>
          Neem je gsm in de hand, verzamel minimum {NECESSARY_CRUMBLES} kruimels
          en ontvang een GRATIS cookie bij Le Pain Quotidien De Wael. Waar wacht
          je op?
        </Typography>
        <Button
          fullWidth
          variant="contained"
          component={Link}
          to={Routes.Permissions}
          id="game_started"
        >
          Start nu
        </Button>
      </Box>
    </>
  );
};

export default Info;
