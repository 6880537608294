import { Box, Typography } from '@mui/material';
import * as Sentry from '@sentry/react';
import { ReactNode } from 'react';

import { FallbackProps } from './boundary.types';

const Fallback = ({ error }: FallbackProps) => {
  return (
    <Box
      width="100vw"
      height="100vh"
      bgcolor="#3349E8"
      color="white"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Box mb="30px">
        <Typography variant="h1">Huston we got a problem!</Typography>
      </Box>
      {(error as unknown as Error)?.message}
    </Box>
  );
};

interface Props {
  children: ReactNode;
}

const RouteBoundary = ({ children }: Props) => (
  <Sentry.ErrorBoundary
    beforeCapture={(scope) => scope.setTag('boundary', 'pages')}
    fallback={(props) => <Fallback {...props} />}
  >
    {children}
  </Sentry.ErrorBoundary>
);

export default RouteBoundary;
