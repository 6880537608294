import { Box, Button, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

import { Routes } from 'constants/routes.constants';

import { Header } from 'components/@header';

// TODO -
// iOS : Settings > Privacy > Location Services
// android: Settings > Location
const NoPermissions = () => {
  const navigate = useNavigate();

  function hardRedirect() {
    navigate(Routes.Permissions, { replace: true });
    // window.location.reload(); // Forces reload but does not help on denied permissions
  }

  return (
    <>
      <Header />
      <Box px={3.75} py={10} textAlign="center">
        <Typography variant="h1" fontWeight={700} py={3}>
          Oeps...
        </Typography>
        <Typography variant="h3" py={3} fontWeight={420}>
          We hebben toegang nodig tot je camera, het bewegen van je toestel en
          je locatievoorzieningen om dit spel te spelen.
        </Typography>
        <Typography variant="h3" pt={3} pb={8} fontWeight={420}>
          Geef ons toegang in je instellingen en start je zoektocht.
        </Typography>
        <Button
          fullWidth
          variant="contained"
          component={Link}
          onClick={() => hardRedirect()}
          py={3}
          id="inadequate_permissions_given"
        >
          Ga terug naar start
        </Button>
      </Box>
    </>
  );
};

export default NoPermissions;
