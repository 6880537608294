import { Box, Button, List, ListItem, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { Header } from 'components/@header';

const Conditions = () => {
  const navigate = useNavigate();
  return (
    <>
      <Header />
      <Box px={3.75} py={3}>
        <Typography variant="h2" fontWeight={700} py={1}>
          Onze Actievoorwaarden
        </Typography>
        <Typography py={1}>
          Vind de kruimels en krijg een chocolade cookie (koekje) van het huis
          bij Le Pain Quotidien de Wael
        </Typography>
        <List
          sx={{
            listStyleType: 'none',
            listStylePosition: 'inside',
          }}
        >
          <ListItem sx={{ display: 'list-item', paddingLeft: 0 }}>
            <Typography variant="h3">Artikel 1. Algemeen</Typography>
            <Typography py={1}>
              Dit Wedstrijdreglement is van toepassing op het gratis online
              wedstrijdspel ‘Vind de kruimels en krijg een chocolade cookie
              (koekje) van het huis bij Le Pain Quotidien de Wael’, hierna de
              ‘wedstrijd’ genoemd. <br />
              <br />
              De wedstrijd wordt georganiseerd door PQ Belgium, met
              maatschappelijk adres te Havenlaan 86C, 103B BE-1000 Brussel
              ingeschreven in de Belgische Kruispuntbank van Ondernemingen onder
              het ondernemingsnummer 0810.307.316, hierna de ‘organisator’
              genoemd. <br />
              <br />
              Dit Wedstrijdreglement heeft o.m. betrekking op de voorwaarden
              voor deelname aan de wedstrijd en op het wedstrijdverloop. Het
              loutere feit van deelname aan de wedstrijd impliceert de gehele
              aanvaarding van bepalingen en voorwaarden van onderhavig
              Wedstrijdreglement. <br />
              <br />
              PQ Belgium behoudt zich het recht om onderhavig reglement op
              eender welk tijdstip en zonder aankondiging aan te passen en bij
              te werken.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item', paddingLeft: 0 }}>
            <Typography variant="h3">Artikel 2. Duur</Typography>
            <Typography py={1}>
              De wedstrijd start op 8 november 2023 om 00u01 en eindigt op 8
              februari 2024 om 19u00. De organisator behoudt zich het recht voor
              om de wedstrijd te verlengen of in te korten.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item', paddingLeft: 0 }}>
            <Typography variant="h3">
              Artikel 3. Voorwaarden van deelname
            </Typography>
            <Typography py={1}>
              De wedstrijd is een gratis online spel zonder enige
              aankoopverplichting en vereist het gebruik van een mobiele
              telefoon of tablet (hierna genoemd apparaat). Er is geen download
              vereist van een app, wel een apparaat dat de mogelijkheid biedt om
              een QR-code te scannen met een constante toegang tot het internet
              (bv. via 4G telecomnetwerk) en de toestemming van de deelnemer om
              volgende instellingen te activeren op het apparaat: toegang tot
              camera, bewegen van toestel, de locatie van de deelnemer. <br />
              <br />
              Alle natuurlijke personen mogen deelnemen aan de wedstrijd, met
              uitzondering van de personeelsleden en bestuurders van de
              organisator en eventuele partners die betrokken zijn bij de
              organisatie van de wedstrijd (bv. een marketingbureau). <br />
              <br />
              Minderjarigen (i.e. personen die jonger zijn dan 18 jaar) kunnen
              enkel geldig deelnemen aan de wedstrijd indien zij de voorafgaande
              toestemming hebben van hun wettelijke vertegenwoordigers (ouders
              of voogden) om deel te nemen aan de wedstrijd. De deelname aan
              deze wedstrijd impliceert deze toestemming. De organisator heeft
              het recht een schriftelijke bevestiging van deze toestemming op te
              vragen. <br />
              <br />
              Het online spel kan zonder beperking van maximum aantal deelnames
              worden gespeeld door de deelnemer, mits in acht name van de
              beperkingen zoals opgenomen in artikel 5. De Prijs. <br />
              <br />
              Le Pain Quotidien kan een persoon uitsluiten van deelname aan de
              wedstrijd in geval van niet-naleving van een van de voorwaarden
              voor deelname of in geval van misbruik, misleiding of deelname te
              kwader trouw aan de wedstrijd of andere wedstrijden die (mede)
              worden georganiseerd door Le Pain Quotidien.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item', paddingLeft: 0 }}>
            <Typography variant="h3">Artikel 4. Wedstrijdverloop</Typography>
            <Typography py={1}>
              De deelnemer kan de wedstrijd starten door het scannen van een
              QR-code met een mobiele telefoon of tablet of door te surfen naar
              de website https://dewael- crumble.lepainquotidien.com. Deze
              QR-codes zijn geprint op affiches die te vinden zijn in Q-Park
              Kooldok, Waalsekaai 1 A, 2000 Antwerpen. <br />
              <br />
              Het wedstrijdmechanisme verloopt als volgt: <br />
              <br />
              Na het voldoen aan de bepalingen zoals gesteld onder ‘Artikel 3.
              Voorwaarden van Deelname’ heeft de deelnemer als doel 5 virtuele
              broodkruimels te verzamelen in de omgeving rond (maximum 1 km) de
              winkel van Le Pain Quotidien de Wael. Slaagt de deelnemer erin 5
              broodkruimels (of meer) te verzamelen, kan hij deze inruilen voor
              een unieke code dat in het spel zal worden getoond. Op vertoon van
              deze code aan de kassa van Le Pain Quotidien de Wael (Leopold de
              Waelplaats 8, 2000 Antwerpen) krijgt de deelnemer 1 gratis
              chocolade cookie (zonder afbreuk te doen aan Artikel 5. De Prijs).
              <br />
              <br />
              Om geldig deel te nemen en dus kans te maken op de prijs dient de
              deelnemer het wedstrijdverloop correct te volgen.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item', paddingLeft: 0 }}>
            <Typography variant="h3">Artikel 5. De Prijs</Typography>
            <Typography py={1}>
              De prijs die met het online spel gewonnen kan worden, zonder
              verdere aankoopverplichting, op vertoon van een geldige code is: 1
              x cookie triple chocolate. <br />
              <br />
              - Waarde = 1.95€ per stuk (BTW inbegrepen). <br />
              <br />- Allergenen informatie: deze cookie bevat gluten (tarwe),
              eieren, soja. Doordat we werken in een open keuken, kan deze
              mogelijke sporen bevatten van vis, sesam, noten, selderij, mosterd
              of sulfiet.
              <br />
              <br />
              Alle prijzen zijn persoonsgebonden en zijn derhalve niet
              overdraagbaar aan derden. De winnaar kan de prijs ook niet
              omruilen of inwisselen (bv. in geld of in een ander product te
              koop in de winkel). De unieke code is enkel inwisselbaar tijdens
              de wedstrijdperiode zoals bepaald onder Artikel 2. Duur.
              <br />
              <br />
              Ongeacht het feit dat er geen maximum staat op het aantal
              deelnames, zal een deelnemer slechts maximaal3 prijzen worden
              toegekend.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item', paddingLeft: 0 }}>
            <Typography variant="h3">Artikel 6. Algemeen</Typography>
            <Typography py={1}>
              Door simpelweg deel te nemen, erkennen deelnemers dat ze de regels
              van de wedstrijd hebben geaccepteerd. Er wordt geen
              correspondentie (brieven, e-mails en/of telefoongesprekken)
              gevoerd over het wedstrijdreglement, de organisatie en/of de
              bindende en onherroepelijke resultaten van de wedstrijd. Alle
              aanvullende aankondigingen met betrekking tot de wedstrijd worden
              beschouwd als onderdeel van het reglement. <br />
              <br />
              Deelnemers aan de wedstrijd onthouden zich van negatieve publieke
              communicatie over de wedstrijd en de organisator. <br />
              <br />
              Behoudens in geval van bedrog of grove nalatigheid, is de
              organisator nooit aansprakelijk voor of gehouden tot vergoeding
              van enige schade, direct dan wel indirect, die het gevolg is van
              de deelname aan de wedstrijd, de toekenning van de prijs en/of de
              gevolgen van de aanvaarding van de prijs. <br />
              <br />
              De organisator is evenmin aansprakelijk voor het niet kunnen
              deelnemen aan de wedstrijd of een niet-continue of onderbroken
              verloop van de wedstrijd, ingevolge technische problemen of
              anderszins. <br />
              <br />
              De organisator kan niet aansprakelijk worden gesteld indien, door
              overmacht of om het even welke andere reden buiten de wil van de
              organisator, de wedstrijd wordt gewijzigd, de duur van de
              wedstrijd wordt gewijzigd of indien de wedstrijd wordt
              geannuleerd. <br />
              <br />
              Deelname aan de wedstrijd houdt de verwerking van de
              persoonsgegevens van de deelnemer in. De contactgegevens die de
              deelnemers aan de organisator meedelen, zullen uitsluitend
              gebruikt worden om de deelnemer te informeren over het verloop van
              de wedstrijd en hen eventueel te contacteren met het oog op de
              overhandiging van de prijs. <br />
              <br />
              Deze verwerking vindt plaats in overeenstemming met het privacy-
              en cookiebeleid van de organisator. Indien de deelnemer meer
              informatie wenst aangaande het privacybeleid van de organisator,
              kan hij of zij contact opnemen met de organisator via e-mail of
              dit raadplegen op de website
              (https://www.lepainquotidien.com/be/nl/privacy-policy/) <br />
              <br />
              Elke deelnemer heeft het recht op inzage, verbetering, aanvulling,
              verwijdering of afscherming van alle persoonsgegevens verstrekt
              aan de organisator alsook, in bepaalde gevallen, het recht om
              bezwaar te maken tegen de verwerking of een verzoek te richten tot
              overdraging van de persoonsgegevens. Dit verzoek dient aan de
              organisator te worden gericht op het e-mailadres
              (jeroen.lauwereys@lepainquotidien.com) vergezeld van een kopie van
              de voorkant van de identiteitskaart van de persoon die het verzoek
              richt.
            </Typography>
          </ListItem>
        </List>
        <Button
          fullWidth
          variant="contained"
          onClick={() => navigate(-1)}
          id="read_terms"
        >
          Terug
        </Button>
      </Box>
    </>
  );
};

export default Conditions;
